:root {
  /* 
    mui v4 breakpoints (https://v4.mui.com/customization/breakpoints/)

    value         |0px     600px    960px    1280px   1920px
    key           |xs      sm       md       lg       xl
    screen width  |--------|--------|--------|--------|-------->
    range         |   xs   |   sm   |   md   |   lg   |   xl

    */

  /* 
    Design System
    The content below is based on two design systems
    - Supreme Design System -> https://www.figma.com/design/VUUXlbXHt9fpvrMVIrvhEr/Supreme-Design-System---Employee-Facing?node-id=43-2&t=s1AQpHQkqAkSGh5o-0
    - Web 2.0 Design System -> https://www.figma.com/design/WHlAZ7pzaNtGya42dYZwbj/PHUS-Web2-Design-Library?node-id=1-37&t=VhJM9MKIeKzOhzP4-0

    The website is currently using the Web 2.0 Design System but will change to the Supreme Design System in the future.

    The agent chat is currently using the Supreme Design System while the rest of the app is using the Web 2.0 Design System.

    */

  --breakpoint-xs: 0px;
  --breakpoint-sm: 600px;
  --breakpoint-mobile-breakpoint: 850px;
  --breakpoint-md: 960px;
  --breakpoint-lg: 1280px;
  --breakpoint-xl: 1920px;

  /** These are the Supreme Design System colors */
  --primary-red-900: #af0003;
  --primary-red-700: #e21216;
  --primary-red-500: #ff7a7d;
  --primary-red-300: #ffadaf;
  --primary-red-100: #f4cbcb;

  --primary-neutral-900: #131313;
  --primary-neutral-800: #424242;
  --primary-neutral-700: #5a5a5a;
  --primary-neutral-600: #898989;
  --primary-neutral-500: #a1a1a1;
  --primary-neutral-400: #b8b8b8;
  --primary-neutral-300: #d0d0d0;
  --primary-neutral-200: #e7e7e7;
  --primary-neutral-100: #f3f3f3;

  --primary-success-900: #1c6b13;
  --primary-success-700: #238618;
  --primary-success-500: #4ab53e;
  --primary-success-300: #7dcc74;
  --primary-success-100: #b7ebb1;

  --primary-info-900: #113e7b;
  --primary-info-700: #266dcc;
  --primary-info-500: #3d7acc;
  --primary-info-300: #79a2da;
  --primary-info-100: #dbe4f0;

  --primary-warning-900: #c05107;
  --primary-warning-700: #e6740a;
  --primary-warning-500: #f4a358;
  --primary-warning-300: #fbd0a9;
  --primary-warning-100: #fae4d0;

  --primary-secondary-neon-pink: #ef3e42;
  --primary-secondary-rewards-gold: #e8d088;
  /** END - These are the Supreme Design System colors */

  /** Use this if you have pure white or black */
  --primary-neutral-utility-black: #000;
  --primary-neutral-utility-white: #fff;
  /** END - Use this if you have pure white or black */

  /** These colors are for the Web 2.0 Design System */
  --web-2-primary-neutral-600: #717170;
  --web-2-primary-neutral-400: #cfcecc;
  --web-2-primary-neutral-100: #f7f7f7;
  /** END - These colors are for the Web 2.0 Design System */

  /** Everything below works with the Web 2.0 and Supreme design system */
  --pizza-hut-red: var(--primary-red-700);
  --pizza-hut-red-hover: var(--primary-red-900);
  --regular-body-text: var(--primary-neutral-900);

  --body-link: var(--primary-info-700);
  --body-link-hover: var(--primary-info-900);

  --success: var(--primary-success-700);

  --form-field-border-color: var(--primary-neutral-900);

  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 24px;
  --spacing-6: 32px;
  --spacing-7: 40px;
  --spacing-8: 48px;
  --spacing-9: 56px;
  --spacing-10: 64px;
  --spacing-11: 96px;
  --spacing-12: 160px;

  --border-radius-1: 4px;
  --border-radius-2: 8px;
  --border-radius-button: 50px;
  --border-radius-circle: 100%;
}
