/***************
  ** Chat Window
  ***************/
.modalContainer.embeddedServiceSidebar .dockableContainer {
  width: 380px !important;
  height: 850px !important;
  max-height: 850px !important;
  right: var(--spacing-5) !important;
  bottom: var(--spacing-5) !important;
  border-radius: var(--border-radius-2) !important;
  font-family: 'open_sans';
  font-size: 14px;
}

@media screen and (max-width: 650px) {
  .modalContainer.embeddedServiceSidebar .dockableContainer {
    width: calc(100% - 48px) !important;
  }
}

@media screen and (max-width: 650px) {
  .modalContainer.embeddedServiceSidebar .dockableContainer {
    height: calc(100% - 94px) !important;
    max-height: calc(100% - 94px) !important;
  }
}

@media screen and (min-width: 651px) and (max-height: 900px) {
  .modalContainer.embeddedServiceSidebar .dockableContainer {
    max-height: calc(100% - 114px) !important;
  }
}

.modalContainer.embeddedServiceSidebar .sidebarBody {
  border: 1px solid var(--primary-neutral-600) !important;
  border-top: 0 !important;
  border-bottom-left-radius: var(--border-radius-2) !important;
  border-bottom-right-radius: var(--border-radius-2) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStatePrechatDefaultUI {
  overflow-y: auto;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarForm.formContent {
  height: auto !important;
  margin-bottom: 0 !important;
  overflow-y: visible !important;
}

.embeddedServiceSidebarForm.buttonWrapper {
  padding-top: var(--spacing-1) !important;
  padding-left: var(--spacing-4) !important;
  padding-right: var(--spacing-4) !important;
  position: relative !important;
}

/***************
  ** Header
  ***************/
.modalContainer.embeddedServiceSidebar .chatHeaderBranding {
  border-bottom: 0 !important;
  min-height: 20px !important;
  height: auto !important;
}

.modalContainer.embeddedServiceSidebar .sidebarHeader {
  font-family: 'PizzaHutFont';
  padding-left: 15px !important;
  padding-right: 6px !important;
  height: 40px;
  border: 1px solid var(--pizza-hut-red) !important;
  background-color: var(--pizza-hut-red) !important;
}

.modalContainer.embeddedServiceSidebar .sidebarHeader img {
  max-width: 25px;
}

.modalContainer.embeddedServiceSidebar .sidebarHeader img,
.modalContainer.embeddedServiceSidebar .sidebarHeader h2 {
  margin-top: 3px;
}

.modalContainer.embeddedServiceSidebar .minimizeButton,
.modalContainer.embeddedServiceSidebar .closeButton {
  cursor: pointer;
}

.modalContainer.embeddedServiceSidebar .closeButton {
  display: none;
}

.modalContainer.embeddedServiceSidebar .minimizeButton:hover:before,
.modalContainer.embeddedServiceSidebar .closeButton:hover:before {
  display: none !important;
}

.modalContainer.embeddedServiceSidebar .minimizeButton:hover,
.modalContainer.embeddedServiceSidebar .closeButton:hover {
  background: rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-1) !important;
}

.modalContainer.embeddedServiceSidebar lightning-icon.slds-icon-utility-minimize-window.slds-icon_container {
  display: block;
  margin-top: -12px;
}

.modalContainer.embeddedServiceSidebar .closeButton {
  top: 3px !important;
}

.modalContainer.embeddedServiceSidebar .text.announcement {
  font-size: 14px !important;
  margin: 0 1px !important;
  width: auto !important;
  background-color: var(--primary-neutral-100) !important;
  color: var(--regular-body-text) !important;
}

/***************
  ** Chat Body
  ***************/
.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarButton {
  font-family: 'open_sans_semi';
  background-color: var(--pizza-hut-red) !important;
  border-radius: var(--border-radius-button) !important;
  color: var(--primary-neutral-utility-white) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarButton:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarButton:active {
  background-color: var(--pizza-hut-red-hover) !important;
  text-decoration: none;
  cursor: pointer;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarButton:focus,
.modalContainer.embeddedServiceSidebar .waitingCancelChat.uiButton:focus {
  text-decoration: none;
  box-shadow:
    inset 0 0 8px 0 rgba(0, 0, 0, 0.3),
    0 3px 8px 0 rgba(0, 0, 0, 0.3);
}

.modalContainer.embeddedServiceSidebar .dialog-button-1,
.modalContainer.embeddedServiceSidebar .waitingCancelChat.uiButton {
  background-color: transparent !important;
  color: var(--body-link) !important;
  border: 0 !important;
}

.modalContainer.embeddedServiceSidebar .dialog-button-1:active,
.modalContainer.embeddedServiceSidebar .waitingCancelChat.uiButton:active {
  background-color: var(--primary-neutral-100) !important;
}

.modalContainer.embeddedServiceSidebar .dialog-button-1 span,
.modalContainer.embeddedServiceSidebar .waitingCancelChat.uiButton span {
  color: var(--body-link) !important;
  font-size: 14px;
}

/***************
  ** Chat Dialog Pages
  ***************/
.modalContainer.embeddedServiceSidebar #dialogTextTitle,
.modalContainer.embeddedServiceSidebar .waitingGreeting {
  font-family: 'PizzaHutFont' !important;
  font-size: 20px;
}

.modalContainer.embeddedServiceSidebar #dialogTextBody {
  line-height: 21px !important;
}

.modalContainer.embeddedServiceSidebar .waitingGreeting {
  padding-bottom: 0 !important;
}

.modalContainer.embeddedServiceSidebar .waitingMessage {
  font-size: 14px !important;
}

/***************
  ** Pre-Chat Content
  ***************/
.modalContainer.embeddedServiceSidebar .preChatWelcomeWrapper {
  margin: var(--spacing-5) var(--spacing-4);
  text-align: left;
}

.modalContainer.embeddedServiceSidebar .preChatHeading {
  font-family: 'PizzaHutFont' !important;
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 var(--spacing-2);
}

.modalContainer.embeddedServiceSidebar .preChatWelcomeWrapper p {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

/***************
  ** Chat Form
  ***************/
.modalContainer.embeddedServiceSidebar .fieldList {
  margin: 0 var(--spacing-4) 0 !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarFormField {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-bottom: var(--spacing-4) !important;
}

.modalContainer.embeddedServiceSidebar .uiLabel {
  margin: 0 !important;
  font-size: 14px !important;
  color: var(--regular-body-text) !important;
}

.modalContainer.embeddedServiceSidebar .required {
  display: none;
}

.modalContainer.embeddedServiceSidebar .inputSplitName {
  width: 100%;
}

.modalContainer.embeddedServiceSidebar .uiInput {
  position: relative;
  z-index: 1;
}

.modalContainer.embeddedServiceSidebar .input,
.modalContainer.embeddedServiceSidebar .select {
  font-family: 'open_sans_semi';
  font-size: 15px;
  border: 1px solid var(--regular-body-text) !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0;
  padding: 0 !important;
  height: 30px !important;
  background: transparent;
}

.embeddedServiceSidebarFormField .slds-style-inputtext:focus,
.embeddedServiceSidebarFormField .slds-style-select:focus {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: var(--primary-neutral-utility-black) !important;
  box-shadow: none !important;
}

.embeddedServiceSidebarFormField .slds-style-inputtext:focus ~ .focusField,
.embeddedServiceSidebarFormField .slds-style-select.select:focus ~ .focusField {
  width: 100%;
  height: 1px;
  box-shadow: 0 2px 8px 1px rgb(200 40 40 / 30%) !important;
  border-radius: 0 !important;
  margin-top: -1px;
}

.embeddedServiceSidebarFormField .has-error .slds-style-inputtext:not(:focus) {
  border-color: var(--primary-neutral-600) !important;
}

.modalContainer.embeddedServiceSidebar ul.uiInputDefaultError {
  background-color: var(--primary-neutral-100);
  position: relative;
  z-index: 0;
}

.modalContainer.embeddedServiceSidebar ul.uiInputDefaultError li {
  padding: var(--spacing-2) var(--spacing-4) var(--spacing-2) var(--spacing-6) !important;
  background: no-repeat url('/images/icons/icon-alert-triangle.svg');
  background-position: 8px center;
  background-size: 5%;
  margin: 0 !important;
}

.modalContainer.embeddedServiceSidebar .uiInputDefaultError .form-element__help {
  color: var(--regular-body-text) !important;
}

.modalContainer.embeddedServiceSidebar .has-error {
  margin-bottom: 0 !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarFormField .uiInputSelect:after {
  border-top-color: var(--pizza-hut-red) !important;
  right: 0;
}

.modalContainer.embeddedServiceSidebar .fieldHelperText {
  font-size: 12px;
  margin: 0;
}

/***************
  ** Chat Bubbles
  ***************/
.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChat .messageArea:focus {
  border: none;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChat .chatSessionStartTime,
.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatItem.agent .nameAndTimeContent,
.modalContainer.embeddedServiceSidebar .eventMessage {
  color: var(--primary-neutral-600) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChat .chatSessionStartTime {
  margin-bottom: var(--spacing-5) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatItem.chatMessage {
  margin-bottom: var(--spacing-4) !important;
}

.modalContainer.embeddedServiceSidebar .nameAndTimeDotDivider,
.modalContainer.embeddedServiceSidebar .timeContent {
  display: none !important;
}

.modalContainer.embeddedServiceSidebar .wrapper.agent.embeddedServiceLiveAgentStateChatItem.chatMessage {
  padding: 0 var(--spacing-7) 0 var(--spacing-4) !important;
  overflow: visible !important;
}

.modalContainer.embeddedServiceSidebar .wrapper.chasitor.embeddedServiceLiveAgentStateChatItem.chatMessage {
  padding: 0 var(--spacing-4) 0 var(--spacing-7) !important;
  overflow: visible !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatItem .isLightningOutContext .avatar {
  top: 0 !important;
  width: 40px !important;
  height: 40px !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI .uiOutputRichText {
  color: var(--regular-body-text) !important;
  line-height: 21px;
}

.modalContainer.embeddedServiceSidebar
  .plaintextContent.agent.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI,
.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatItem.agent .agentName,
.embeddedServiceLiveAgentStateChatBubble.agent .chatContent {
  margin-left: var(--spacing-8) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI,
.embeddedServiceLiveAgentStateChatBubble.agent .chatContent {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2) !important;
}

.modalContainer.embeddedServiceSidebar .agent.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI,
.embeddedServiceLiveAgentStateChatBubble.agent .chatContent {
  max-width: calc(100% - 48px) !important;
  border-radius: 0 var(--border-radius-2) var(--border-radius-8) !important;
  background: var(--primary-neutral-utility-white) !important;
  border: 1px solid var(--primary-neutral-300) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatTypingIndicator .typingIndicatorBall {
  background: var(--regular-body-text) !important;
}

.modalContainer.embeddedServiceSidebar .agent.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI {
  max-width: calc(100% - 48px) !important;
  border-radius: 0 var(--border-radius-2) var(--border-radius-2) !important;
  background: var(--primary-neutral-utility-white) !important;
  border: 1px solid var(--primary-neutral-300) !important;
}

.modalContainer.embeddedServiceSidebar .chasitor.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI {
  max-width: 100% !important;
  border-radius: var(--border-radius-2) 0 var(--border-radius-2) var(--border-radius-2) !important;
  background: var(--primary-neutral-200) !important;
  border: 1px solid var(--primary-neutral-300) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatInputFooter.chasitorInputWrapper {
  background-color: var(--primary-neutral-utility-white) !important;
  border-top: 1px solid var(--primary-neutral-utility-black);
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatInputFooter.dynamicResizeTextOneRow {
  height: auto;
  min-height: auto;
}

.modalContainer.embeddedServiceSidebar .chasitorControls {
  margin: 0 !important;
  height: 100% !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceLiveAgentStateChatInputFooter .chasitorControls .uiInput {
  font-size: 16px !important;
  padding: var(--spacing-4) !important;
}

.modalContainer.embeddedServiceSidebar
  .embeddedServiceLiveAgentStateChatInputFooter.dynamicResizeTextOneRow
  .chasitorText {
  padding: var(--spacing-2) !important;
  border-radius: 0 0 0 var(--border-radius-2) !important;
}

.embeddedServiceLiveAgentStateChatInputFooter .chasitorText.textAreaIsFocused {
  border: 1px solid var(--primary-neutral-600) !important;
  border-radius: 0 0 0 var(--border-radius-2) !important;
}

.modalContainer.embeddedServiceSidebar button.sendButtonChatWrapper {
  background: white;
  border: 0;
  font-size: 16px;
  padding: 0 var(--spacing-4);
  cursor: pointer;
  color: var(--pizza-hut-red);
  font-weight: 700;
}

/***************
  ** Online Chat Button
  ***************/
.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarMinimizedDefaultUI,
.modalContainer.embeddedServiceSidebar .helpButton.embeddedServiceSidebarMinimizedDefaultUI {
  min-width: 74px !important;
  max-width: 74px !important;
  height: 74px;
  border-radius: var(--border-radius-circle) !important;
  right: var(--spacing-5) !important;
  bottom: var(--spacing-5) !important;
  border: 0 !important;
  padding: 0 !important;
  box-shadow: none !important;
  overflow: visible;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarMinimizedDefaultUI .messageContent,
.modalContainer.embeddedServiceSidebar .helpButton.embeddedServiceSidebarMinimizedDefaultUI .messageContent {
  margin: var(--spacing-1) 0 0 1px;
  background: no-repeat center url('/images/icons/icon-chat-white.svg');
  background-size: 59%;
}

.modalContainer.embeddedServiceSidebar .helpButton.embeddedServiceSidebarMinimizedDefaultUI .minimizedText,
.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarMinimizedDefaultUI .minimizedText {
  display: none;
}

.modalContainer.embeddedServiceSidebar .notificationChatWrapper {
  min-width: 37px;
  height: 37px;
  position: absolute;
  background: var(--primary-neutral-utility-black);
  padding: var(--spacing-1);
  border-radius: var(--border-radius-circle);
  z-index: 1;
  border: 2px solid var(--primary-neutral-utility-white);
  top: -1px;
  right: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'open_sans_semi';
  font-size: 20px;
}

/***************
  ** Online Pre-Chat Minimized Button
  ***************/
.modalContainer.embeddedServiceSidebar .helpButton.embeddedServiceSidebarMinimizedDefaultUI:active {
  background-color: var(--pizza-hut-red-hover) !important;
}

.modalContainer.embeddedServiceSidebar .helpButton.embeddedServiceSidebarMinimizedDefaultUI:focus {
  box-shadow:
    inset 0 0 8px 0 rgba(0, 0, 0, 0.1),
    0 3px 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.modalContainer.embeddedServiceSidebar .helpButton.embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
  display: none;
}

/***************
  ** Online Chat Minimized Button
  ***************/

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarMinimizedDefaultUI .content {
  padding: 0 !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarMinimizedDefaultUI:focus {
  box-shadow:
    inset 0 0 8px 0 rgba(0, 0, 0, 0.1),
    0 3px 8px 0 rgba(0, 0, 0, 0.1) !important;
}

.modalContainer.embeddedServiceSidebar .embeddedServiceSidebarMinimizedDefaultUI .minimizedImage {
  display: none;
}
