.pac-container {
    z-index: 1050;
}

[tabindex]:not([tabindex='-1']):focus:not([data-focus-visible-added]) {
    outline: none;
    outline: -webkit-focus-ring-color 0;
}

[tabindex]:not([tabindex='-1'])[data-focus-visible-added],
[tabindex]:not([tabindex='-1']) .Mui-focusVisible[data-focus-visible-added] {
    outline: 3px dotted #E21216;
}

[tabindex]:not([tabindex='-1']):focus:not(:focus-visible) {
    outline: none;
    outline: -webkit-focus-ring-color 0;
}

[tabindex]:not([tabindex='-1']):focus-visible,
[tabindex]:not([tabindex='-1']) .Mui-focusVisible:focus-visible {
    outline: 3px dotted #E21216;
}

[tabindex='-10']:focus-within {
    outline: 3px dotted #E21216;
}

iframe {
    border: 0;
}